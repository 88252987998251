import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

import { FuseSharedModule } from "@fuse/shared.module";

import { LoginComponent } from "app/main/login/login.component";

import { HTTP_INTERCEPTORS } from "@angular/common/http";
import {
	MSAL_GUARD_CONFIG,
	MSAL_INSTANCE,
	MSAL_INTERCEPTOR_CONFIG,
	MsalGuard,
	MsalGuardConfiguration,
	MsalInterceptor,
	MsalInterceptorConfiguration,
	MsalModule,
	MsalRedirectComponent,
	MsalService,
} from "@azure/msal-angular";
import { BrowserCacheLocation, IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from "@azure/msal-browser";

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

// const routes = [
//     {
//         path     : '**',
//         component: LoginComponent
//     }
// ];
const routes = [
	{
		path: "login",
		component: LoginComponent,
	},
	{
		path: "login-cas",
		component: LoginComponent,
	},
];

export function loggerCallback(logLevel: LogLevel, message: string) {
	console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
	return new PublicClientApplication({
		auth: {
			clientId: "9af8d1b8-8546-4f90-922e-af1a5019801f",
			authority: "https://login.microsoftonline.com/common",
			redirectUri: window.location.protocol + "//" + window.location.host,
		},
		cache: {
			cacheLocation: BrowserCacheLocation.LocalStorage,
			storeAuthStateInCookie: isIE, // set to true for IE 11
		},
		system: {
			loggerOptions: {
				loggerCallback,
				logLevel: LogLevel.Info,
				piiLoggingEnabled: false,
			},
		},
	});
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
	const protectedResourceMap = new Map<string, Array<string>>();
	protectedResourceMap.set("https://graph.microsoft.com/v1.0/me", ["user.read", "calendars.readbasic", "calendars.read", "calendars.readwrite"]);

	return {
		interactionType: InteractionType.Popup,
		protectedResourceMap,
	};
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
	return {
		interactionType: InteractionType.Popup,
		authRequest: {
			scopes: ["user.read"],
		},
	};
}

@NgModule({
	declarations: [LoginComponent],
	imports: [
		RouterModule.forChild(routes),

		MatButtonModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		TranslateModule,

		FuseSharedModule,

		MsalModule,
	],
	bootstrap: [MsalRedirectComponent],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: MsalInterceptor,
			multi: true,
		},
		{
			provide: MSAL_INSTANCE,
			useFactory: MSALInstanceFactory,
		},
		{
			provide: MSAL_GUARD_CONFIG,
			useFactory: MSALGuardConfigFactory,
		},
		{
			provide: MSAL_INTERCEPTOR_CONFIG,
			useFactory: MSALInterceptorConfigFactory,
		},
		MsalService,
		MsalGuard,
	],
})
export class LoginModule {}
