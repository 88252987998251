<div id="login" fxLayout="column">
	<div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">
		<div *ngIf="!showRemember" id="login-form"
			[@animate]="{ value: '*', params: { duration: '800ms', y: '500px' } }">
			<div class="logo">
				<img *ngIf="entryPointResources !== ''" [src]="imageSvc.getLogo()" />
				<img *ngIf="entryPointResources === ''" src="assets/images/logos/logo.png" />
			</div>

			<form *ngIf="canLogin()" name="loginForm" [formGroup]="loginForm" (ngSubmit)="onLogin()" novalidate>
				<mat-form-field appearance="outline">
					<mat-label>{{ "login.username" | translate }}</mat-label>
					<input matInput formControlName="usuario" autocomplete="off" />
					<mat-icon matSuffix class="secondary-text">person_outline</mat-icon>
					<mat-error *ngIf="loginForm.get('usuario').hasError('required')">
						{{ "login.error.username" | translate }}
					</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline">
					<mat-label>{{ "login.password" | translate }}</mat-label>
					<input matInput type="password" formControlName="password" autocomplete="off" />
					<mat-icon matSuffix class="secondary-text">lock</mat-icon>
					<mat-error>
						{{ "login.error.password" | translate }}
					</mat-error>
				</mat-form-field>

				<button mat-raised-button color="accent" class="submit-button w-100-p" aria-label="Iniciar sesión">
					<!-- [disabled]="loginForm.invalid"> -->
					{{ "login.login" | translate }}
				</button>
			</form>

			<div class="separator">
				<span class="text">O</span>
			</div>

			<button *ngIf="canLoginGoogle()" mat-raised-button class="google w-100-p" (click)="loginGoogle()">
				{{ "login.google" | translate }}
			</button>

			<button *ngIf="canLoginOutlook()" mat-raised-button class="outlook w-100-p" (click)="loginOutlook()">
				{{ "login.outlook" | translate }}
			</button>

			<div *ngIf="canRememberPassword()" class="remember-forgot-password mb-4" fxLayout="row" fxLayout.xs="column"
				fxLayoutAlign="center center">
				<a class="forgot-password" (click)="showRemember = true">
					{{ "login.forgot.pass" | translate }}
				</a>
			</div>

			<div fxLayout="row" fxLayoutAlign="center center" class="h-10" style="position: relative">
				<mat-error *ngIf="msgErrorLogin && msgErrorLogin !== ''" style="position: absolute; top: 2px">{{
					msgErrorLogin }}</mat-error>
			</div>
		</div>

		<div *ngIf="showRemember" id="remember-form"
			[@animate]="{ value: '*', params: { duration: '800ms', y: '500px' } }">
			<div class="logo">
				<img *ngIf="entryPointUrl !== ''" [src]="imageSvc.getLogo()" />
				<img *ngIf="entryPointUrl === ''" src="assets/images/logos/logo.png" />
			</div>

			<h5 class="text">{{ "login.forgot.pass.help2" | translate }}</h5>

			<form name="rememberForm" [formGroup]="rememberForm" (ngSubmit)="onRemember()" novalidate>
				<mat-form-field appearance="outline">
					<mat-label>{{ "person_edit.email" | translate }}</mat-label>
					<input matInput formControlName="emailRemember" />
					<mat-icon matSuffix class="secondary-text">person_outline</mat-icon>
					<mat-error *ngIf="loginForm.get('usuario').hasError('required')">
						{{ "login.error.username" | translate }}
					</mat-error>
				</mat-form-field>

				<button mat-raised-button color="accent" class="submit-button w-100-p" aria-label="Enviar">
					{{ "general.button.send" | translate }}
				</button>

				<div class="remember-forgot-password mb-4" fxLayout="row" fxLayout.xs="column"
					fxLayoutAlign="center center">
					<a class="forgot-password" (click)="showRemember = false">
						{{ "general.button.cancel" | translate }}
					</a>
				</div>
			</form>

			<div fxLayout="row" fxLayoutAlign="center center" class="h-10" style="position: relative">
				<mat-error *ngIf="msgErrorRemember && msgErrorRemember !== ''" style="position: absolute; top: 2px">{{
					msgErrorRemember }}</mat-error>
			</div>
		</div>

		<div *ngIf="msgSuccessRemember && msgSuccessRemember !== ''" fxLayout="row" fxLayoutAlign="center center"
			class="h-10" style="width: 100%" [@animate]="{ value: '*', params: { duration: '800ms', y: '500px' } }">
			<span style="position: absolute; top: 14px; color: green">{{ msgSuccessRemember }}</span>
		</div>
	</div>
</div>